import React, { Component } from "react";
import Header from "../../components/header/Header";
import StackProgress from "../../containers/skillProgress/skillProgress";
import SkillLanguage from "../../containers/skillLanguage/skillLanguage";
import SkillTools from "../../containers/skillTools/skillTools";
import TopButton from "../../components/topButton/TopButton";
import "./skillTree.css";
import SkillProjectManagement from "../../containers/skillProjectManagement/projectManagement";

class SkillTree extends Component {
  render() {
    return (
      <div className="skill-main">
        <Header theme={this.props.theme} />
        <StackProgress theme={this.props.theme} />
        <SkillLanguage theme={this.props.theme} />
        <SkillTools theme={this.props.theme} />
        <SkillProjectManagement theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default SkillTree;
