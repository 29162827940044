import React from "react";
import "./projectManagement.css";
import { projectManagement } from "../../portfolio";
import { Fade } from "react-reveal";

export default function SkillProjectManagement(props) {
  if (projectManagement.viewSkillBars) {
    return (
      <Fade bottom duration={2000} distance="40px">
        <div className="tool-heading">
          <h1>Project Management</h1>
        </div>
        <div className="tool-container">
          <Fade left duration={1500}>
            <div className="tool-bar">
              {projectManagement.experience
                .slice(0, Math.ceil(projectManagement.experience.length / 2))
                .map((exp, key) => {
                  const progressStyle = {
                    width: exp.progressPercentage,
                  };
                  return (
                    <div className="tool" key={key}>
                      <p>{exp.Stack}</p>
                      <div className="tool-meter">
                        <span style={progressStyle}></span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Fade>

          <Fade right duration={1500}>
            <div className="tool-bar">
              {projectManagement.experience
                .slice(Math.ceil(projectManagement.experience.length / 2))
                .map((exp, key) => {
                  const progressStyle = {
                    width: exp.progressPercentage,
                  };
                  return (
                    <div className="tool" key={key}>
                      <p>{exp.Stack}</p>
                      <div className="tool-meter">
                        <span style={progressStyle}></span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Fade>
        </div>
      </Fade>
    );
  }
  return null;
}
