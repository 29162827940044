import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import "./ExperienceTimeLine.css";

class ExperienceTimeLine extends Component {
  render() {
    const experiencesDetails = this.props.sections;
    return (
      <>
        {experiencesDetails.map((elm, index) => {
          return (
            <VerticalTimeline key={index} layout="2-columns">
              {elm.experiences.map((exp, i) => {
                return (
                  <VerticalTimelineElement
                    key={i}
                    className="vertical-timeline-element--work"
                    contentStyle={{
                      background: "rgb(33, 150, 243)",
                      color: "#fff",
                    }}
                    contentArrowStyle={{
                      borderRight: "7px solid  rgb(33, 150, 243)",
                    }}
                    date={exp.duration}
                    dateClassName="date-color"
                    iconStyle={{
                      background: "rgb(33, 150, 243)",
                      color: "#fff",
                    }}
                    icon={<FontAwesomeIcon icon={faBriefcase} />}
                  >
                    <h3 className="vertical-timeline-element-title">
                      {exp.title} | {exp.company}
                    </h3>
                    <h4 className="vertical-timeline-element-subtitle">
                      {exp.location}
                    </h4>
                    <p>{exp.description}</p>
                  </VerticalTimelineElement>
                );
              })}
            </VerticalTimeline>
          );
        })}
      </>
    );
  }
}

export default ExperienceTimeLine;
